.App {
  text-align: center;
  /*background: #282c34;*//*url('background.jpg') no-repeat;*/
  background: #FFFAF1;
  background-size: cover;
  background-position: center;
}

.App-Nav {
  display: block;
}

.App-scroll-container {
  display: block;
  margin: 0 auto;
  text-align: center;
  background-color: transparent;/*#282c34;*/
  height: 92.2vh;
  overflow-y: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
  color: #1f1d1b;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-progress-bar {
  position: relative;
  top: 0;
  left: 0;
  height: 0.3vh; /* Adjust as needed */
  background-color: #007bff; /* Choose your color */
  z-index: 100; /* Ensure it's on top */
}

.grad-color {
  --bg-size: 400%;
  /*--color-one: #2b5876;*/
  /*--color-two: #4e4376;*/
  --color-one: hsl(15 90% 55%);
  --color-two: hsl(40 95% 55%);
  font-size: clamp(3rem, 25vmin, 8rem);
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: move-bg 8s infinite linear;
}

@keyframes move-bg {
  to {
    background-position: 400% 0;
  }
}
