.gallery {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.gallery .text {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.gallery .text p {
  text-align: left;
}

.gallery .img {
  width: 40%;
}

img {
  width: 100%;
}

button.btn-grad {
  /*background-image: linear-gradient(to right, #2b5876 0%, #4e4376  51%, #2b5876  100%);*/
  background-image: linear-gradient(to right, hsl(15 90% 55%) 0%, hsl(40 95% 55%)  51%, hsl(15 90% 55%)  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

button.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

