.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*background-color: #282c34;*/
  background-color: #FFFAF1;
  /*color: #fff;*/
  color: #1f1d1b;
  opacity:0.9;
  padding: 0.25rem;
  /*padding-top: 3vh;*/
  width: 100%;
  height: 4.7vh;
}


.navbar-left .logo {
  position: relative;
  left: 20%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.navbar-center .nav-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-center .nav-links li {
  margin-right: 1rem;
}

.navbar-center .nav-links a {
  color: #1f1d1b;
  text-decoration: none;
  /*font-size: 1.5rem;*/
  font-size: calc(5px + 2vmin);
}

.navbar-center .nav-links a .logo {
  font-size: 1.8rem;
}

.navbar-center .nav-links a:hover {
  color: var(--gray-light);
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right .user-icon {
  position: relative;
  right: 20%;
  font-size: 1.6rem;
  color: #fff;
  text-decoration: none;
  margin-top: 10px;
  margin-left: 1rem;
  position: relative;
}

.navbar-right .user-icon:hover {
  color: var(--gray-light);
}
